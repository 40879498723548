<script>
  // @ts-check
  import slugify from "slugify";
  import { octokit } from "./api";

  /** @type {string} */
  export let repo;

  let sending = false;
  let error;
  /** @type {string} */
  let prLink;
  /** @type {string} */
  let title = "";
  $: slug = slugify(title).toLowerCase();
  /** @type {FileList} */
  let files;
  /**
   * @param {Event & { currentTarget: EventTarget & HTMLFormElement; }} event
   */
  async function submitBookForm(event) {
    sending = true;
    error = null;
    const file = files[0];
    try {
      const repoParams = { owner: "nyu-dss", repo };
      const branch = `book/${slug}`;

      const indexBlob = octokit().rest.git.createBlob({
        ...repoParams,
        content: await file.text(),
      });

      const baseRef = await octokit().rest.git.getRef({
        ...repoParams,
        ref: "heads/main",
      });
      const sha = baseRef.data.object.sha;
      const [, baseTree] = await Promise.all([
        octokit().rest.git.createRef({
          ...repoParams,
          ref: `refs/heads/${branch}`,
          sha,
        }),
        await octokit().rest.git.getTree({
          ...repoParams,
          tree_sha: sha,
        }),
      ]);

      const tree = await octokit().rest.git.createTree({
        ...repoParams,
        tree: [
          {
            sha: (await indexBlob).data.sha,
            path: `_html/${slug}/index.html`,
            mode: "100644",
          },
        ],
        base_tree: baseTree.data.sha,
      });

      const commit = await octokit().rest.git.createCommit({
        ...repoParams,
        message: title,
        tree: tree.data.sha,
        parents: [baseRef.data.object.sha],
      });

      await octokit().rest.git.updateRef({
        ...repoParams,
        ref: `heads/${branch}`,
        sha: commit.data.sha,
      });

      const pr = await octokit().pulls.create({
        ...repoParams,
        base: "main",
        head: branch,
        title: `Add ${title}`,
      });
      prLink = pr.data.html_url;
    } catch (err) {
      error = err;
    } finally {
      sending = false;
    }
  }
</script>

<form on:submit|preventDefault={submitBookForm}>
  {#if error}
    <div class="alert alert-danger" role="alert">
      Error uploading book: {error}
    </div>
  {/if}
  <fieldset disabled={sending}>
    <div class="form-group">
      <label for="title">Title: </label>
      <input type="text" id="title" bind:value={title} class="form-control" />
      {#if slug}
        <small>
          (slug will be <code>{slug}</code>)
        </small>
      {/if}
    </div>

    <div class="form-group">
      <label for="file">HTML file: </label>
      <input type="file" id="file" bind:files class="form-control-file" />
    </div>

    <button class="btn btn-primary">Create book</button>
    {#if prLink}
      <div class="alert alert-success" role="alert">
        Pull request created! Please wait for the Action to finish, and then refresh. See <a
          href={prLink}
          rel="noreferrer"
          target="_blank">the progress here</a
        >.
      </div>
    {/if}
  </fieldset>
</form>
