import GithubRepositories from "../GithubRepositories.svelte";
import SvelteController from "./svelte_controller";

export default class extends SvelteController {
  static values = {
    repositoryUrl: String,
    org: {
      type: String,
      default: "nyu-dss",
    },
  };
  component = GithubRepositories;
}
