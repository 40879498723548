<script>
  // @ts-check
  import { octokit } from "./api";
  import Loading from "./common/Loading.svelte";

  /** @type {string} */
  export let repositoryUrl;
  /** @type {string} */
  export let org;

  $: reposPromise = octokit().rest.repos.listForOrg({ org, type: "private" });
</script>

{#await reposPromise}
  <Loading title="repositories" />
{:then res}
  {#each res.data.filter((r) => r.name.endsWith("-data")) as repo}
    <a
      href={`${repositoryUrl}?name=${repo.name}&branch=${repo.default_branch}`}
      class="list-group-item list-group-item-action"
    >
      {repo.name}
    </a>
  {/each}
{:catch error}
  {error}
  <a href="/">Maybe try the login page again?</a>
{/await}
