import { Controller } from "@hotwired/stimulus";
import { Octokit } from "@octokit/rest";
import { get } from "svelte/store";
import { panel } from "../stores";

export class OctokitController extends Controller {
  get auth() {
    if (!this._auth && window.sessionStorage.auth) {
      this._auth = JSON.parse(window.sessionStorage.auth);
    }

    return this._auth;
  }

  set auth(data) {
    window.sessionStorage.auth = JSON.stringify(data);

    this._auth = data;
  }

  get user() {
    if (!this._user && window.sessionStorage.user) {
      this._user = JSON.parse(window.sessionStorage.user);
    }

    return this._user;
  }

  set user(data) {
    window.sessionStorage.user = JSON.stringify(data);

    this._user = data;
  }

  get octokit() {
    if (!this._octokit) {
      const auth = this.auth.token;
      const userAgent = "NYUPanel";
      const timeZone = "America/New_York";
      const log = {
        debug: console.log,
        info: console.log,
        warn: console.warn,
        error: console.error,
      };

      this._octokit = new Octokit({
        auth,
        userAgent,
        log,
      });
    }

    return this._octokit;
  }

  get params() {
    if (!this._params) {
      this._params = new URLSearchParams(window.location.search);
    }

    return this._params;
  }

  get panel() {
    return get(panel);
  }

  /**
   * @param {any} data
   * @param {HTMLTemplateElement} template
   * @returns {DocumentFragment}
   */
  applyTemplate(data, template = undefined) {
    if (!template) {
      console.log("default template");
      template = this.templateTarget;
    }

    if (!template) {
      console.error("Can't find template", data);
      return;
    }

    const elementContainer = template.content.cloneNode(true);

    for (const key in data) {
      const dasherizedKey = this.dasherize(key);
      const attribute = `data-${dasherizedKey}`;
      const contentAttribute = `${attribute}-content`;
      const datasetAttribute = `${attribute}-data`;
      const classAttribute = `${attribute}-class`;

      const attributeSelector = `[${attribute}]`;
      const contentSelector = `[${contentAttribute}]`;
      const datasetSelector = `[${datasetAttribute}]`;
      const classSelector = `[${classAttribute}]`;

      for (const element of elementContainer.querySelectorAll(
        attributeSelector
      )) {
        element.setAttribute(dasherizedKey, data[key]);
        element.removeAttribute(attribute);
      }

      for (const element of elementContainer.querySelectorAll(
        contentSelector
      )) {
        element.textContent = data[key];
        element.removeAttribute(contentAttribute);
      }

      for (const element of elementContainer.querySelectorAll(
        datasetSelector
      )) {
        element.dataset[key] = data[key];
        element.removeAttribute(datasetAttribute);
      }

      for (const element of elementContainer.querySelectorAll(classSelector)) {
        if (data[key]) element.classList.add(data[key]);
        element.removeAttribute(classAttribute);
      }
    }

    return elementContainer;
  }

  // this comes from Stimulus but isn't exported
  camelize(value) {
    return value.replace(/(?:[_-])([a-z0-9])/g, (_, char) =>
      char.toUpperCase()
    );
  }

  // this comes from Stimulus but isn't exported
  dasherize(value) {
    return value.replace(/([A-Z])/g, (_, char) => `-${char.toLowerCase()}`);
  }
}
