<script>
  // @ts-check

  /** @type {string} */
  export let branch;

  /**
   * @param {string} name
   */
  function unslugify(name) {
    // XXX: hacky
    return name
      .replaceAll("-", " ")
      .replaceAll(
        /(^| )(\w)/g,
        (sub, ...args) => args[0] + args[1].toUpperCase()
      )
      .replaceAll("Childrens", "Children's");
  }

  $: name = unslugify(branch.slice("book/".length));
</script>

{name}
