import { OctokitController } from "./octokit_controller";
const { createTokenAuth } = require("@octokit/auth-token");

export default class extends OctokitController {
  static targets = ["token"];
  static values = { nextPage: String };

  async login(event) {
    event.preventDefault();

    this.auth = await createTokenAuth(this.tokenTarget.value)();

    try {
      const response = await this.octokit.rest.users.getAuthenticated();
      this.user = response.data;
      Turbo.visit(this.nextPageValue);
    } catch (error) {
      alert(error);
    }
  }
}
