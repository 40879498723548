import { Controller } from "@hotwired/stimulus";

export default class SvelteController extends Controller {
  component;
  _runningComponent;

  connect() {
    const props = Object.fromEntries(
      Object.keys(this.constructor.values || {}).map((k) => [
        k,
        this[`${k}Value`],
      ])
    );
    this._runningComponent = new this.component({
      target: this.element,
      props,
    });
  }
  disconnect() {
    this._runningComponent.$destroy();
  }
}
