<script>
  import CreateBookForm from "./CreateBookForm.svelte";

  // @ts-check
  import BookName from "./BookName.svelte";
  import { octokit } from "./api";
  import Loading from "./common/Loading.svelte";
  import { queryParams } from "./helpers";

  /** @type {string} */
  export let branchUrl;
  /** @type {string} */
  export let owner;
  const repo = queryParams().get("name");

  $: branchesPromise = octokit()
    .rest.repos.listBranches({
      owner,
      repo,
    })
    .then((r) => r.data.filter((b) => b.name.startsWith("book/")));
</script>

<details>
  <summary>Create book</summary>
  <CreateBookForm {repo} />
</details>

{#await branchesPromise}
  <Loading title="branches" />
{:then branches}
  {#each branches as branch}
    <a
      href={`${branchUrl}?repo=${repo}&name=${branch.name}`}
      class="list-group-item list-group-item-action"
      class:active={branch.name === queryParams().get("branch")}
    >
      <BookName branch={branch.name} />
    </a>
  {/each}
{:catch error}
  {error}
  <a href="/">Maybe try the login page again?</a>
{/await}
