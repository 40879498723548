<script>
  // @ts-check
  import AddToBookForm from "./AddToBookForm.svelte";
  import { getAuth, octokit } from "./api";
  import Loading from "./common/Loading.svelte";
  import { queryParams } from "./helpers";
  import { panel } from "./stores";

  /** @type {string} */
  export let worksCitedUrl;

  class PanelJsonNotFoundError extends Error {}
  class CouldntParsePanelJsonError extends Error {}

  const owner = "nyu-dss";
  const ref = queryParams().get("name");
  $: bookName = ref.slice("book/".length);
  const repo = queryParams().get("repo");

  async function getPanelJson() {
    const res = await octokit().rest.repos.getContent({
      owner,
      ref,
      repo,
      path: "_data/panel.json.link",
      mediaType: { format: "json" },
    });
    if (
      !("data" in res) ||
      res.data instanceof Array ||
      !(res.status >= 200 && res.status <= 299)
    )
      throw new Error("Unexpected response from API");
    const link = await (await fetch(res.data.download_url)).text();
    const panelData = await // TODO: don't use proxy
    (
      await fetch("https://corsproxy.io/?" + link)
    ).json();

    $panel = panelData;
    try {
      return Object.entries(panelData.posts)
        .map(([path, post]) => ({ path, ...post }))
        .filter((p) => p.layout !== "work_cited" && p.layout !== "person")
        .filter((p) => p.url.split("/")[1] === bookName)
        .sort((a, b) => a.title.localeCompare(b.title));
    } catch (error) {
      console.error("Couldn't parse panel.json:", error);
      throw new CouldntParsePanelJsonError(
        `Couldn't parse panel.json, please report this. ${error}`
      );
    }
  }

  $: postsPromise = getPanelJson();
</script>

{#await postsPromise}
  <Loading title="posts" />
{:then posts}
  <details>
    <summary>Update book</summary>
    <AddToBookForm {repo} bookSlug={bookName} />
  </details>

  {#each posts as post}
    <div
      class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
    >
      <a href={`${worksCitedUrl}?repo=${repo}&branch=${ref}&path=${post.path}`}
        >{post.title}</a
      >
      <span class="badge badge-primary">{post.layout}</span>
    </div>
  {/each}
{:catch error}
  {error}
  {#if error instanceof PanelJsonNotFoundError}
    <a href={`https://github.com/${owner}/${repo}/commits/${ref}`}
      >Check the Action status here, and refresh this page when it finishes</a
    >.
  {:else}
    <a href="/">Maybe try the login page again?</a>
  {/if}
{/await}
