// @ts-check
import { writable } from "svelte/store";
import { get, set } from "idb-keyval";

// wipe previous panel saved as it's now saved in indexeddb
sessionStorage.panel = "";

export const panel = writable(null);
(async () => {
  panel.set(await get("panel"));
})();
panel.subscribe((val) => set("panel", val));
