// @ts-check
/**
 * @typedef {Object} Auth
 * @property {string} token
 */

import { Octokit } from "@octokit/rest";

/**
 * @returns {Auth}
 */
export function getAuth() {
  return JSON.parse(window.sessionStorage.auth || "{}");
}
/**
 * @param {Auth} data
 */
export function setAuth(data) {
  window.sessionStorage.auth = JSON.stringify(data);
}

let _octokit;
let _usedAuth;
/**
 * conseguir instancia de octokit
 * @returns {Octokit}
 */
export function octokit() {
  if (!_octokit || _usedAuth.token !== getAuth().token) {
    _usedAuth = getAuth();
    const auth = _usedAuth.token;
    const userAgent = "NYUPanel";
    const log = {
      debug: console.log,
      info: console.log,
      warn: console.warn,
      error: console.error,
    };

    _octokit = new Octokit({
      auth,
      userAgent,
      log,
    });
  }

  return _octokit;
}
