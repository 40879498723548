import { Notifier } from "@airbrake/browser";

// window.airbrake = new Notifier({
//   projectId: process.env.AIRBRAKE_PROJECT_ID,
//   projectKey: process.env.AIRBRAKE_PROJECT_KEY,
//   host: "https://panel.sutty.nl",
// });

console.originalError = console.error;
console.error = (...e) => {
  // window.airbrake.notify(e.join(" "));
  return console.originalError(...e);
};

import "core-js/stable";
import "regenerator-runtime/runtime";

import * as Turbo from "@hotwired/turbo";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

Turbo.start();

const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);

application.load(definitionsFromContext(context));
