import { OctokitController } from "./octokit_controller";

export default class extends OctokitController {
  static targets = ["brand", "avatar", "name"];

  brandTargetConnected(brand) {
    if (!this.user) return;

    brand.href = `https://github.com/${this.user.login}`;
    this.element.hidden = false;
  }

  avatarTargetConnected(avatar) {
    if (!this.user) return;

    avatar.src = this.user.avatar_url;
    this.element.hidden = false;
  }

  nameTargetConnected(name) {
    if (!this.user) return;

    name.innerText = this.user.name || this.user.login;
    this.element.hidden = false;
  }
}
