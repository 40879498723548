import GithubRepository from "../GithubRepository.svelte";
import { queryParams } from "../helpers";
import SvelteController from "./svelte_controller";

export default class extends SvelteController {
  static values = {
    branchUrl: String,
    owner: {
      type: String,
      default: "nyu-dss",
    },
  };
  component = GithubRepository;
}
