import { OctokitController } from "./octokit_controller";
import YAML from "yaml";
import escapeStringRegexp from "escape-string-regexp";

const commonmark = require("commonmark/dist/commonmark");
const lunr = require("lunr");
const Enc = require("@root/encoding/base64");

export default class extends OctokitController {
  static targets = [
    "search",
    "list",
    "itemTemplate",
    "datalistTemplate",
    "citeTemplate",
    "cite",
    "loading",
    "createWorkCited",
    "content",
    "saveHelp",
  ];
  static values = {
    owner: {
      type: String,
      default: "nyu-dss",
    },
    searching: {
      type: Boolean,
      default: false,
    },
    sha: String,
  };

  static citeRe = /\([^\)]*\d{2,4}([a-z])?(, ([ivxlcm]+|\d+(-\d+)?))?\)/g;
  static parenthesisRe = /[\(\)]/g;
  static citesRe = /;\s*/g;

  async connect() {
    if (this.loadingTarget.hidden) return;
    if (!this.panel.layouts.work_cited) return;

    const owner = this.ownerValue;
    const repo = this.params.get("repo");
    const ref = this.params.get("branch");
    const path = this.params.get("path");
    const format = "json";
    const mediaType = { format };
    const response = await this.octokit.rest.repos.getContent({
      owner,
      repo,
      ref,
      path,
      mediaType,
    });

    if (response.status >= 400) {
      this.loadingTarget.innerHTML = "<p>Error.</p>";
    } else {
      this.loadingTarget.hidden = true;

      this.responseData = response.data;
      this.shaValue = this.responseData.sha;
      /** @type {string[]} */
      this.fullContent = Enc.base64ToStr(this.responseData.content).split(
        /---/,
        3
      );
      this.frontMatter = this.fullContent[1];
      this.frontMatterObject = YAML.parse(this.fullContent[1]);
      this.originalContent = this.fullContent[2];
      /** @type {string} */
      this.replacedContent = this.originalContent.replace(
        this.constructor.citeRe,
        (m) => this.addPlaceholderCites(m)
      );
      this.content = this.originalContent.replace(
        this.constructor.citeRe,
        (m) => this.addCites(m)
      );

      this.contentTarget.innerHTML = this.writer.render(
        this.reader.parse(this.content)
      );
    }
  }

  selectWorkCited(event) {
    const selection = document.getSelection();
    const selectedText = selection.toString().trim();

    this.createWorkCitedTarget.disabled = selectedText.length === 0;
  }

  /**
   * @param {Event} event
   */
  createWorkCited(event) {
    event.preventDefault();

    // TODO: tirar y mostrar errores en estos returns
    const selection = document.getSelection().getRangeAt(0);
    if (!selection) return;
    // chequeamos que la seleccion esté dentro del contenido
    if (!this.contentTarget.contains(selection.commonAncestorContainer)) return;
    // chequeamos que solo haya texto porque no podemos correctamente reemplazar la cita en el markdown si tiene otro formateo
    {
      const contentsFragment = selection.cloneContents();
      const isOnlyText = Array.from(contentsFragment.childNodes).every(
        (node) => node.nodeType === Node.TEXT_NODE
      );
      if (!isOnlyText) return;
    }
    // chequeamos que la selección no esté vacía
    const selectionText = selection.toString().trim();
    if (!selectionText) return;
    // chequeamos que no esté el mismo texto en otra parte del markdown porque sino no podemos estar segurxs que estamos reemplazando el texto correcto
    if (
      this.replacedContent.indexOf(selectionText) !== -1 &&
      this.replacedContent.indexOf(selectionText) !==
        this.replacedContent.lastIndexOf(selectionText)
    )
      return;

    const replaced = this.replacedContent.replace(
      selectionText,
      citeMarkdown(selectionText)
    );
    // aún así, no pudimos reemplazar en el markdown :(
    if (this.replacedContent === replaced) return;
    this.replacedContent = replaced;

    const fragment = this.applyCiteTemplate(selectionText);
    const el = fragment.children[0];
    selection.deleteContents();
    selection.insertNode(fragment);
    this.selectCite(el);
  }

  get searchIndex() {
    if (!this._searchIndex) {
      this.searchTarget.disabled = true;

      const lunrBuilder = new lunr.Builder();
      lunrBuilder.pipeline.add(lunr.trimmer, lunr.stopWordFilter, lunr.stemmer);
      lunrBuilder.field("title");

      for (const id of this.panel.layouts.work_cited) {
        const title = this.panel.posts[id].title;

        lunrBuilder.add({ id, title });
      }

      this._searchIndex = lunrBuilder.build();

      this.searchTarget.disabled = false;
    }

    return this._searchIndex;
  }

  /**
   * @param {string} cite
   */
  applyCiteTemplate(cite) {
    return this.applyTemplate({ cite }, this.citeTemplateTarget);
  }

  addCites(match) {
    let cites = match;

    for (const cite of cites.split(this.constructor.citesRe)) {
      const element = this.applyCiteTemplate(cite);

      cites = cites.replace(cite, element.firstElementChild.outerHTML);
    }

    return cites;
  }

  addPlaceholderCites(match) {
    let cites = match;

    for (const cite of cites.split(this.constructor.citesRe)) {
      cites = cites.replace(cite, `[${escapeStringRegexp(cite)}](URL)`);
    }

    return cites;
  }

  /**
   * @param {Event} event
   */
  selectCiteListener(event) {
    event.preventDefault();
    this.selectCite(event.target);
  }

  /**
   * @param {HTMLElement & { dataset: { cite: string } }} el
   */
  selectCite(el) {
    const citeSearch = el.dataset.cite
      .replaceAll(this.constructor.parenthesisRe, "")
      .trim();

    this.selectedCite = el;
    this.searchTarget.value = citeSearch;
    this.search();
  }

  apply(event) {
    event.preventDefault();

    if (!this.selectedCite) {
      console.error(
        "Applied cite without selecting cite",
        event.target.dataset.title
      );
      return;
    }

    const { title, path, url } = event.target.dataset;

    this.selectedCite.href = url;
    this.selectedCite.title = title;
    this.selectedCite.dataset.cited = true;

    const escapedCite = escapeStringRegexp(this.selectedCite.dataset.cite);
    const citeLink = escapeStringRegexp(`[${escapedCite}](URL_PLACEHOLDER)`);
    const citeRe = new RegExp(citeLink.replace("URL_PLACEHOLDER", "[^\\)]+"));

    this.replacedContent = this.replacedContent.replace(citeRe, (m) => {
      return citeMarkdown(this.selectedCite.dataset.cite, url);
    });
  }

  search(event = undefined) {
    event?.preventDefault();

    if (this.searchingValue) return;
    this.searchingValue = true;

    const q = this.searchTarget.value.trim();

    this.listTarget.innerHTML = "";

    if (q.length > 0) {
      const searchResults = this.searchIndex.search(q);

      for (const result of searchResults) {
        const workCited = this.panel.posts[result.ref];
        const path = result.ref;
        const title = workCited.title;
        const url = workCited.url;
        const data = { title, path, url };

        this.listTarget.appendChild(
          this.applyTemplate(data, this.itemTemplateTarget)
        );
      }
    }

    this.searchingValue = false;
  }

  toggle(event) {
    event.target.disabled = !event.target.disabled;
  }

  async save(event = undefined) {
    if (event) {
      event.preventDefault();
      event.target.disabled = true;
    }

    this.saveHelpTarget.innerText = "Saving...";
    let cleanedContent = this.replacedContent;

    for (const citeTarget of this.citeTargets) {
      if ("cited" in citeTarget.dataset) continue;

      const cite = citeTarget.dataset.cite;
      const escapedCite = escapeStringRegexp(cite);
      const citeRe = new RegExp(escapeStringRegexp(`[${escapedCite}](URL)`));

      cleanedContent = cleanedContent.replace(citeRe, cite);
    }

    const owner = this.ownerValue;
    const repo = this.params.get("repo");
    const branch = this.params.get("branch");
    const path = this.params.get("path");
    const message = `Adding works cited to ${path} [skip actions]`;
    const sha = this.shaValue;
    const { email, login } = this.user;
    const name = login;
    const committer = { email, name };
    const author = committer;
    const content = Enc.strToBase64(
      `---${this.frontMatter}---${cleanedContent}`
    );
    let saveMessage;

    try {
      const response = await this.octokit.rest.repos.createOrUpdateFileContents(
        {
          owner,
          repo,
          path,
          message,
          content,
          committer,
          author,
          sha,
          branch,
        }
      );

      this.shaValue = response.data.content.sha;
      saveMessage = "Saved!";
    } catch (e) {
      console.error(`Saving ${path}`, e);

      saveMessage = "There was an error and it was reported :)";
    }

    this.saveHelpTarget.innerText = saveMessage;

    if (event) event.target.disabled = false;
  }

  get reader() {
    if (!this._reader) {
      this._reader = new commonmark.Parser({
        smart: true,
        footnotes: true,
        unsafe: true,
      });
    }

    return this._reader;
  }

  get writer() {
    if (!this._writer) {
      this._writer = new commonmark.HtmlRenderer({
        strikethrough: true,
        autolink: true,
        table: true,
      });
    }

    return this._writer;
  }
}

/**
 * genera el markdown de una cita
 * @param {string} cite
 * @param {string} url?
 * @returns {string}
 */
function citeMarkdown(cite, url = "URL") {
  const escapedCite = escapeStringRegexp(cite);
  return `[${escapedCite}](${url})`;
}
