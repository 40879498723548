<script>
  export let title = "";
</script>

<h1>Loading{title ? " " + title : ""}...</h1>

<p class="lead">
  If this page takes too long you may not have configured your <abbr
    title="Personal Access Token">PAT</abbr
  >
  correctly or it may have expired. Or another network error may have happened.
  Please <a href="/">go back to the login page and try again</a>.
</p>
